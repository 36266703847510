.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

ul.pagination {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-page,
.pagination-break,
.pagination-previous,
.pagination-next {
  background-color: #fff;
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

/* Atur warna latar belakang dan teks untuk elemen aktif */
.pagination-active {
  background-color: #1c8aff;
  color: #fff;
}

/* Tambahan: menghilangkan bullet pada tampilan ul dan ol secara umum */
ul,
ol {
  list-style: none;
  padding-left: 0;
}
